// import routes from "@/routes/routes.mjs";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  createCategory,
  QUEUE_CATEGORIES,
} from "@/game-lol/utils/coaching-utils.mjs";
import Mask from "@/inline-assets/hextech-minion-mask.svg";
import Swords from "@/inline-assets/hextech-stats-melee.svg";
import hasQueryString from "@/util/has-query-string.mjs";
import { initABFeature } from "@/util/use-ab-feature.mjs";

const FEATURE_AB_PERCENTAGE = 20;

export async function setup() {
  const enabled =
    (await initABFeature(
      ["coaching", "lol-postmatch-coaching"],
      FEATURE_AB_PERCENTAGE,
    )) || hasQueryString("__BLITZ_COACHING__");

  if (!enabled) return;

  QUEUE_CATEGORIES[QUEUE_SYMBOLS.rankedSoloDuo] = [
    createCategory({
      key: "combat",
      title: ["lol:coaching.combat", "Combat"],
      subtitle: [
        "lol:coaching.combatExplanation",
        "Your combat score represents your performance at battling enemies",
      ],
      icon: Swords,
      feedback: {
        supertitle: ["lol:coaching.deaths.title", "Deaths Performance"],
        title: null,
        subtitle: null,
        content: null,
      },
    }),
    createCategory({
      key: "farming",
      title: ["lol:coaching.farming", "Farming"],
      subtitle: [
        "lol:coaching.farmingExplanation",
        "Your farming score represents your performance at gaining power",
      ],
      icon: Mask,
      feedback: {
        supertitle: ["lol:coaching.farmingPerformance", "Farming Performance"],
        title: null,
        subtitle: null,
        content: null,
      },
    }),
  ];

  const component = await import("feature-lol-coaching/PostMatchCoaching.jsx");
  lolRefs.PostMatchCoachingSection = component.default;
}

export function teardown() {
  lolRefs.PostMatchCoachingSection = null;
}
